import React from "react";
import { Modal } from "antd";
import "./index.less";
const bg_zp_huodong = require("../../../ActivityDescription/assets/bg_zp_huodong@3x.png");
const guize = require("../../../ActivityDescription/assets/guize.jpg");
const btn_close_line = require("../../../../assets/btn_close_line@3x.png");
const window = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false); // todo 调试弹窗
  const close = () => {
    setVisible(false);
  };
  const show = () => {
    setVisible(true);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
    };
  });
  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={close}
      bodyStyle={{
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
      }}
      style={{ top: 30 }}
      //   width={322}
      closable={false}
      footer={null}
    >
      <div className="rule-pages">
        <div className="header_top">
          <img
            src={bg_zp_huodong}
            style={{
              width: 88,
              height: 31,
            }}
          ></img>
          <img
            src={btn_close_line}
            onClick={() => {
              close();
            }}
            style={{
              width: 30,
              height: 30,
              position: "absolute",
              right: 10,
              top: 0,
            }}
          ></img>
        </div>
        <div className="main_center">
          <img className="main_img" src={guize}></img>
        </div>
      </div>
    </Modal>
  );
});

export default window;
