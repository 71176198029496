import React from "react";
import { Modal, InputNumber, message, Input } from "antd";
import "./index.less";

const btn_close_line = require("../../../../assets/btn_close_line@3x.png");

const InputNumberModel = React.forwardRef((props, ref) => {
  const { lottery, data, index } = props;

  const [count, setCount] = React.useState(0);
  const [visible, setVisible] = React.useState(false);

  const close = () => {
    setVisible(false);
  };

  const show = () => {
    setVisible(true);
  };

  React.useImperativeHandle(ref, () => {
    return {
      show: show,
    };
  });

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={close}
      bodyStyle={{
        backgroundColor: "transparent",
      }}
      width={322}
      closable={false}
      footer={null}
    >
      <div className="input-number-Model">
        <div className="input-number-Model-container">
          <div className="input-number-Model-title">请输入抽奖次数</div>

          <div className="input-number-Model-input">
            <Input
              bordered={false}
              placeholder="请输入5-200之间的数字哦～"
              style={{
                width: "100%",
              }}
              value={count === 0 ? "" : String(count)}
              type="number"
              controls={false}
              onChange={(e) => {
                let value = e.target.value;
                if (value?.length === 0) {
                  setCount(0);
                } else {
                  setCount(Math.min(200, Number(value)));
                }
              }}
            />
          </div>
          <div className="input-number-Model-footer">
            <div
              className="input-number-Model-footer-first"
              onClick={() => {
                setVisible(false);
              }}
            >
              取消
            </div>
            <div
              className="input-number-Model-footer-second"
              onClick={() => {
                let target = Number(count);

                if (target >= 5 && target <= 200) {
                  setVisible(false);
                  props?.onChange && props?.onChange(target);
                } else {
                  message.error("请输入5-200之间的数字");
                }
              }}
            >
              确定
            </div>
          </div>
        </div>
        <div
          className="goods-model-close"
          onClick={() => {
            setVisible(false);
          }}
        >
          {/* <img
            src={btn_close_line}
            style={{
              width: 36,
              height: 36,
            }}
            alt=""
          /> */}
        </div>
      </div>
    </Modal>
  );
});

export default InputNumberModel;
