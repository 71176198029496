import React from "react";
import "./index.less";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const btn_nav_black = require("../../assets/btn_nav_black@3x.png");
const bg_zp_huodong = require("./assets/bg_zp_huodong@3x.png");
const guize = require("./assets/guize.jpg");

const ActivityDescription = (props) => {
  const navigate = useNavigate();
  const config = useSelector((state) => state.App.config);
  return (
    <div className="rule-page">
      <div className="header">
        <div className="winning-record-text">
          <img
            src={bg_zp_huodong}
            style={{
              width: 88,
              height: 31,
            }}
            alt=""
          />
        </div>
        <div className="winning-record-close">
          <img
            src={btn_nav_black}
            style={{
              width: 24,
              height: 24,
            }}
            alt=""
            onClick={() => {
              navigate("/dazhuanpan");
            }}
          />
        </div>
      </div>
      <div className="rulepage">
        {/* {config?.business_dazhuanpan_desc_img_upload_url && (
             <img width={'100%'} src={config?.business_dazhuanpan_desc_img_upload_url} />
          )} */}
        <img width={"100%"} style={{ marginTop: 20 }} src={guize} />
      </div>
    </div>
  );
};

export default ActivityDescription;
