import React from "react";
import { Modal, List, message, Button, getPrefixCls } from "antd";
import "./index.less";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import Http from "../../../../utils/Http";
const bg_zp_huodong = require("../../../ActivityDescription/assets/bg_zp_huodong@3x.png");
const bg_zp_zhongjiang = require("../../../WinningRecord/assets/bg_zp_zhongjiang@3x.png");
const btn_close_line = require("../../../../assets/btn_close_line@3x.png");
const zhongWindow = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false); // todo 调试弹窗
  const close = () => {
    setVisible(false);
    setData([])
    setPage(1)
    setHaveMore(true);
  };
  const show2 = () => {

    setVisible(true);
  };
  // const [data, setData] = React.useState([
  //   {
  //     id: 2318,
  //     created_at: "2024-02-02T11:17:53+08:00",
  //     updated_at: "2024-02-02T11:17:53+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973250430878355456",
  //     tx_no: "LSTX973250431029350400",
  //     product_id: 44,
  //     goods_id: 72,
  //     goods_info: {
  //       id: 72,
  //       created_at: "2022-05-21T17:40:29+08:00",
  //       updated_at: "2024-02-02T11:17:53+08:00",
  //       name: "魔法棒",
  //       desc: "魔法棒",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/9cfdfb1c-9ce9-41a7-b12b-d8b35ab02d67.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/802c3a7d-f294-4989-8560-8b98206eac14.svga.svga",
  //       show_resource_type: "full",
  //       tid: "MODABANG",
  //       can_sale: 0,
  //       sale_price: "10",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 5,
  //   },
  //   {
  //     id: 2317,
  //     created_at: "2024-02-02T11:17:53+08:00",
  //     updated_at: "2024-02-02T11:17:53+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973250430878355456",
  //     tx_no: "LSTX973250430999990272",
  //     product_id: 37,
  //     goods_id: 59,
  //     goods_info: {
  //       id: 59,
  //       created_at: "2022-05-21T15:46:47+08:00",
  //       updated_at: "2024-02-02T11:17:53+08:00",
  //       name: "我们结婚吧",
  //       desc: "我们结婚吧",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/161d23d1-c84c-4ab5-a36f-4e4f05314ecb.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/2625c60d-89ce-4443-b8fc-71b2a3f16f95.svga.svga",
  //       show_resource_type: "full",
  //       tid: "WOMENJIEHUNBA",
  //       can_sale: 0,
  //       sale_price: "10",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 4,
  //   },
  //   {
  //     id: 2316,
  //     created_at: "2024-02-02T11:17:53+08:00",
  //     updated_at: "2024-02-02T11:17:53+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973250430878355456",
  //     tx_no: "LSTX973250430974824448",
  //     product_id: 36,
  //     goods_id: 58,
  //     goods_info: {
  //       id: 58,
  //       created_at: "2022-05-21T15:44:57+08:00",
  //       updated_at: "2024-02-02T11:17:53+08:00",
  //       name: "情侣（不唯一)",
  //       desc: "情侣",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/4d69d32d-6e40-453b-8bdc-ee56a6d95ca2.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/3b3ce4a3-faa1-44eb-9dc0-7a21d92eb847.svga.svga",
  //       show_resource_type: "full",
  //       tid: "QINGLV",
  //       can_sale: 0,
  //       sale_price: "10",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 1,
  //   },
  //   {
  //     id: 2315,
  //     created_at: "2024-02-02T11:11:56+08:00",
  //     updated_at: "2024-02-02T11:11:56+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973248935512510464",
  //     tx_no: "LSTX973248935646728192",
  //     product_id: 38,
  //     goods_id: 60,
  //     goods_info: {
  //       id: 60,
  //       created_at: "2022-05-21T15:48:42+08:00",
  //       updated_at: "2024-02-02T11:11:56+08:00",
  //       name: "鲸梦",
  //       desc: "鲸梦",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/f64ff103-f954-4fd9-9578-0c5a2ca0ae81.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/41c8c59a-0598-4179-9809-29fdd0ad5e32.svga.svga",
  //       show_resource_type: "full",
  //       tid: "JINGMENG",
  //       can_sale: 0,
  //       sale_price: "10",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 1,
  //   },
  //   {
  //     id: 2314,
  //     created_at: "2024-02-02T11:11:56+08:00",
  //     updated_at: "2024-02-02T11:11:56+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973248935512510464",
  //     tx_no: "LSTX973248935625756672",
  //     product_id: 37,
  //     goods_id: 59,
  //     goods_info: {
  //       id: 59,
  //       created_at: "2022-05-21T15:46:47+08:00",
  //       updated_at: "2024-02-02T11:17:53+08:00",
  //       name: "我们结婚吧",
  //       desc: "我们结婚吧",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/161d23d1-c84c-4ab5-a36f-4e4f05314ecb.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/2625c60d-89ce-4443-b8fc-71b2a3f16f95.svga.svga",
  //       show_resource_type: "full",
  //       tid: "WOMENJIEHUNBA",
  //       can_sale: 0,
  //       sale_price: "10",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 3,
  //   },
  //   {
  //     id: 2313,
  //     created_at: "2024-02-02T11:11:56+08:00",
  //     updated_at: "2024-02-02T11:11:56+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973248935512510464",
  //     tx_no: "LSTX973248935596396544",
  //     product_id: 36,
  //     goods_id: 58,
  //     goods_info: {
  //       id: 58,
  //       created_at: "2022-05-21T15:44:57+08:00",
  //       updated_at: "2024-02-02T11:17:53+08:00",
  //       name: "情侣（不唯一)",
  //       desc: "情侣",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/4d69d32d-6e40-453b-8bdc-ee56a6d95ca2.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/3b3ce4a3-faa1-44eb-9dc0-7a21d92eb847.svga.svga",
  //       show_resource_type: "full",
  //       tid: "QINGLV",
  //       can_sale: 0,
  //       sale_price: "10",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 2,
  //   },
  //   {
  //     id: 2312,
  //     created_at: "2024-02-02T11:11:56+08:00",
  //     updated_at: "2024-02-02T11:11:56+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973248935512510464",
  //     tx_no: "LSTX973248935575425024",
  //     product_id: 45,
  //     goods_id: 74,
  //     goods_info: {
  //       id: 74,
  //       created_at: "2022-05-21T17:44:20+08:00",
  //       updated_at: "2024-02-02T11:11:56+08:00",
  //       name: "怦然心动",
  //       desc: "怦然心动",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/dcefc888-7828-47ac-aa47-d8769b92eaf4.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/b3d63edc-d7f4-4589-bdd5-b172acfcea3e.svga.svga",
  //       show_resource_type: "full",
  //       tid: "PENGRANXINDONG",
  //       can_sale: 0,
  //       sale_price: "52",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 2,
  //   },
  //   {
  //     id: 2311,
  //     created_at: "2024-02-02T11:11:56+08:00",
  //     updated_at: "2024-02-02T11:11:56+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973248935512510464",
  //     tx_no: "LSTX973248935546064896",
  //     product_id: 44,
  //     goods_id: 72,
  //     goods_info: {
  //       id: 72,
  //       created_at: "2022-05-21T17:40:29+08:00",
  //       updated_at: "2024-02-02T11:17:53+08:00",
  //       name: "魔法棒",
  //       desc: "魔法棒",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/9cfdfb1c-9ce9-41a7-b12b-d8b35ab02d67.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/802c3a7d-f294-4989-8560-8b98206eac14.svga.svga",
  //       show_resource_type: "full",
  //       tid: "MODABANG",
  //       can_sale: 0,
  //       sale_price: "10",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 2,
  //   },
  //   {
  //     id: 2310,
  //     created_at: "2024-02-02T11:11:51+08:00",
  //     updated_at: "2024-02-02T11:11:51+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973248911248461824",
  //     tx_no: "LSTX973248911319764992",
  //     product_id: 44,
  //     goods_id: 72,
  //     goods_info: {
  //       id: 72,
  //       created_at: "2022-05-21T17:40:29+08:00",
  //       updated_at: "2024-02-02T11:17:53+08:00",
  //       name: "魔法棒",
  //       desc: "魔法棒",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/9cfdfb1c-9ce9-41a7-b12b-d8b35ab02d67.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/802c3a7d-f294-4989-8560-8b98206eac14.svga.svga",
  //       show_resource_type: "full",
  //       tid: "MODABANG",
  //       can_sale: 0,
  //       sale_price: "10",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 1,
  //   },
  //   {
  //     id: 2309,
  //     created_at: "2024-02-01T21:45:28+08:00",
  //     updated_at: "2024-02-01T21:45:28+08:00",
  //     user_id: 41,
  //     user_info: {
  //       id: 41,
  //       created_at: "2022-03-30T17:53:52+08:00",
  //       updated_at: "2022-06-23T15:11:44+08:00",
  //       uid: "27899117663",
  //       nickname: "忠厚的裴珊",
  //       sex: "0",
  //       avatar:
  //         "https://wan2store-download-1307024140.cos.ap-shanghai.myqcloud.com/file/dd5358f9-6181-4f08-ab54-2da3fb2978ec.png",
  //       is_pretty: 0,
  //     },
  //     lucky_bot_game_id: 1,
  //     lottery_tx_no: "LRTX973045978930089984",
  //     tx_no: "LSTX973045979072696320",
  //     product_id: 36,
  //     goods_id: 58,
  //     goods_info: {
  //       id: 58,
  //       created_at: "2022-05-21T15:44:57+08:00",
  //       updated_at: "2024-02-02T11:17:53+08:00",
  //       name: "情侣（不唯一)",
  //       desc: "情侣",
  //       item_class: "1",
  //       item_type: "6",
  //       item_level: "1",
  //       is_in_package: 1,
  //       is_unique: 0,
  //       can_use: 1,
  //       attr_info: "",
  //       sequence: 0,
  //       effect_param: "",
  //       icon_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/4d69d32d-6e40-453b-8bdc-ee56a6d95ca2.png",
  //       show_resource_path:
  //         "https://shenlian.oss-cn-shenzhen.aliyuncs.com/file/3b3ce4a3-faa1-44eb-9dc0-7a21d92eb847.svga.svga",
  //       show_resource_type: "full",
  //       tid: "QINGLV",
  //       can_sale: 0,
  //       sale_price: "10",
  //       can_combine: 0,
  //       is_stack: 1,
  //       stack_limit: 999999,
  //       giftwall_status: 1,
  //     },
  //     count: 4,
  //   },
  // ]);
  React.useImperativeHandle(ref, () => {
    return {
      show2: show2,
    };
  });
  const Item = (props) => {
    const { item } = props;
    return (
      <div className="winning-record-item">
        <div className="winning-record-item-left">
          <div className="winning-record-item-container">
            <div className="winning-record-item-img">
              <img
                style={{
                  width: 20,
                  height: 20,
                }}
                src={item?.goods_info?.icon_path}
                alt=""
              />
            </div>
            <div className="winning-record-item-name">
              <span
                style={{
                  fontSize: 15,
                  color: "white",
                }}
              >
                {item?.goods_info?.name}{" "}
              </span>
              <span
                style={{
                  fontSize: 15,
                  color: "#F5DB49",
                }}
              >
                *{item?.count}
              </span>
            </div>
          </div>
          <div className="winning-record-item-description">
            {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
          </div>
        </div>
        <div className="winning-record-item-right">
          {item?.goods_info?.sale_price}声豆
        </div>
      </div>
    );
  };
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [pers, setPers] = React.useState(10);
  const [hasMore, setHaveMore] = React.useState(true);
  const loadMoreData = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!hasMore) {
      message.warning("没有更多了");
      setTimeout(() => {
        setLoading(false);
      }, 500);

      return;
    }
    ///api/v1/turntable/lotteryRes  摩天轮旧
    try {
      const res = await Http.get("/api/v1/lucky_bot_game/lotteryUserRes", {
        page: page,
        pers: pers,
        category: 0,
        id: 1,
      });

      if (res?.status === 10000) {
        setData(data.concat(res?.data));

        if (!res.has_next) {
          setHaveMore(false);
        }

        setPage(page + 1);
      } else {

        message.warning(res?.message);
      }
    } catch (error) {

      message.warning("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      loadMoreData();
    } else {
      setPage(1);
      // setData([]);
    }
  }, [visible]);

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={close}
      bodyStyle={{
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
      }}
      style={{ top: 30 }}
      //   width={322}
      closable={false}
      footer={null}
    >
      <div className="rule-pages">
        {/* {contextHolder} */}
        <div className="header_top">
          <img
            src={bg_zp_zhongjiang}
            style={{
              width: 88,
              height: 31,
            }}
          ></img>
          <img
            src={btn_close_line}
            onClick={() => {
              close();
            }}
            style={{
              width: 30,
              height: 30,
              position: "absolute",
              right: 10,
              top: 0,
            }}
          ></img>
        </div>
        <div
          style={{
            // flex: 1,
            display: "flex",
            height: "490px",
            flexDirection: "column",
            overflow: "auto",
          }}
          id="scrollableDiv"
        >
          {data.length > 0 ? (
            <InfiniteScroll
              dataLength={data.length}
              next={loadMoreData}
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={data}
                renderItem={(item) => <Item key={item.id} item={item} />}
              />
            </InfiniteScroll>
          ) : (
            <div className="winning-record-empty">暂无数据</div>
          )}
        </div>
        <div
          onClick={() => {
            loadMoreData();
          }}
          style={{ color: "#fff", textAlign: "center" }}
        >
          <Button type="primary" ghost loading={loading}>
            加载更多
          </Button>
        </div>
      </div>
    </Modal>
  );
});
export default zhongWindow;
