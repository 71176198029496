import React from "react";
import { LuckyWheel } from "@lucky-canvas/react";
import { useInterval } from "usehooks-ts";

// const bg_zp_chuji_1_1 = require('../../../../assets/bg_zp_chuji_1_1_a@3x.png');
// const bg_zp_chuji_1 = require('../../../../assets/bg_zp_chuji_1_a@3x.png');
// const bg_zp_chuji_2 = require('../../../../assets/bg_zp_chuji_2_a@3x.png');
// const bg_zp_chuji_3 = require('../../../../assets/bg_zp_chuji_3_a@3x.png');
// // const bg_zp_chuji_3 = require('./assets/bg_zp_chuji_3@3x.png');
// const bg_zp_chuji_4 = require('../../../../assets/bg_zp_chuji_4_a@3x.png');
const bg_zp_chuji_1_1 = require("../../../../assets/motianlun/ray.png");
const bg_zp_chuji_1 = require("../../../../assets/motianlun/ray2.png");
const bg_zp_chuji_2 = require("../../../../assets/bg_zp_chuji_2_a@3x.png");
const bg_zp_chuji_3 = require("../../../../assets/motianlun/highYuanpan.png");
// const bg_zp_chuji_3 = require('./assets/bg_zp_chuji_3@3x.png');
const bg_zp_chuji_4 = require("../../../../assets/motianlun/highGo.png");
const bg_zp_chuji_5 = require("../../../../assets/motianlun/highHome.png");

const formatPrize = (item) => {
  return {
    imgs: [
      {
        src: item?.product_info?.goods_info?.icon_path,
        width: "45px",
        top: "15%",
        left: "3px",
      },
    ],
    // fonts: [
    //   {
    //     text: `${item?.product_info?.skuList[0]?.pay_amount}${item?.product_info?.asset_info?.display_name}`,
    //     fontColor: "#5131cd",
    //     top: "80%",
    //     fontSize: "12px",
    //   },
    // ],
  };
};

const AdvanceWheel = React.forwardRef((props, ref) => {
  const { id, productList, findIndex, showGiftModel, selectState, lottery } =
    props;

  const [bg, setBg] = React.useState(true);
  const playState = React.useRef({
    more: false,
    ids: [],
  });
  const selectStateRef = React.useRef({
    more: false,
    ids: [],
  });

  const [blocks, setBlacks] = React.useState([
    {
      padding: "20px",
      imgs: [
        {
          src: bg_zp_chuji_1_1,
          width: "100%",
          height: "100%",
          rotate: true,
        },
      ],
    },
    // {
    //   padding: "12px",
    //   imgs: [
    //     {
    //       src: bg_zp_chuji_2,
    //       width: "100%",
    //       height: "100%",
    //       rotate: true,
    //     },
    //   ],
    // },
    {
      imgs: [
        {
          src: bg_zp_chuji_3,
          width: "95%",
          height: "95%",
          top: "2px",
          rotate: true,
        },
      ],
    },
  ]);
  const [prizes, setPrizes] = React.useState(productList?.map(formatPrize));
  const [buttons] = React.useState([
    // {
    //   radius: "45%",
    //   imgs: [
    //     {
    //       src: bg_zp_chuji_4,
    //       // width: "100%",
    //       // top: "-280%",
    //       width: "46%",
    //       top: "-35",
    //     },
    //   ],
    // },
    {
      fonts: [
        {
          text: "",
          top: "-14px",
          fontSize: 22,
          fontColor: "#ffffff",
        },
      ],
    },
  ]);
  const myLucky = React.useRef();

  useInterval(() => {
    if (bg) {
      setBlacks((pre) => {
        pre[0].imgs[0].src = bg_zp_chuji_1_1;

        return [...pre];
      });
    } else {
      setBlacks((pre) => {
        pre[0].imgs[0].src = bg_zp_chuji_1;

        return [...pre];
      });
    }
    setBg(!bg);
  }, 800);

  React.useEffect(() => {
    setPrizes(productList?.map(formatPrize));
  }, [productList]);

  const onEnd = () => {
    if (playState.current.ids.length === 0) {
      showGiftModel();
      return;
    }

    let item = playState.current.ids.shift();

    if (playState.current.more) {
      start(item, 1000);
      playState.current.more = false;
    } else {
      start(item, 2000);
    }
  };

  const stop = (index) => {
    myLucky.current.stop(index);
  };

  const start = (index, duration) => {
    //myLucky.current.play();

    setTimeout(() => {
      stop(index);
    }, duration);
  };

  const play = (count, data) => {
    if (count > 1) {
      let max = Number(data[0]?.pay_amount);
      let max_index = 0;

      data?.forEach((item, index) => {
        if (Number(item.pay_amount) > max) {
          max_index = index;
          max = Number(item.pay_amount);
        }
      });

      let target = findIndex(data[max_index]?.goods_info);

      playState.current = {
        more: true,
        ids: [target],
      };
    } else {
      let length = data.length;
      let target = findIndex(data[length - 1]?.goods_info);

      playState.current = {
        more: false,
        ids: [target],
      };
    }
    let item = playState.current.ids.shift();

    if (playState.current.more) {
      start(item, 1000);
      playState.current.more = false;
    } else {
      start(item, 2000);
    }
  };

  const onStart = () => {
    lottery && lottery(selectStateRef.current.id, selectStateRef.current.count);
  };

  const loading = (count, data) => {
    myLucky.current.play();
  };

  React.useEffect(() => {
    selectStateRef.current = {
      ...selectState,
      id: id,
    };
  }, [selectState, id]);

  React.useImperativeHandle(ref, () => {
    return {
      play: play,
      stop: stop,
      loading: loading,
      onStart: onStart,
    };
  });

  return (
    <LuckyWheel
      ref={myLucky}
      width={"360px"}
      height={"360px"}
      blocks={blocks}
      prizes={prizes}
      buttons={buttons}
      defaultConfig={{
        speed: 6,
        offsetDegree: 20,
        accelerationTime: 2500,
        decelerationTime: 2500,
      }}
      onEnd={onEnd}
      onStart={onStart}
    />
  );
});

export default AdvanceWheel;
